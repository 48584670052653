import { PiletApi } from 'piral';
import * as React from 'react';

/**
 * This is a list of all the extensions that are available in pilets.
 *
 * These are used to optionally show an element in another pilet based on whether the page is visible or not.
 */
export class Pilets {
  static notificationPreferences = 'pages-preferences';
  static customerProfile = 'pages-customer-profile';
  static personalDetails = 'pages-personal-details';
}

/**
 * Register a pilet as an extension with the provided page.
 *
 * @param {PiletApi} piral The Pilet API instance.
 * @param {string} pageName The name of the page to register the extension with.
 */
export function registerPiletToggleExtension(piral: PiletApi, pageName: string): void {
  piral.registerExtension(pageName, ({ params }): JSX.Element => {
    return params.element;
  });
}

export const pageToggle =
  (piral: PiletApi): React.FC<{ pageName: string; children: JSX.Element }> =>
  (props: { pageName: string; children: JSX.Element }) =>
    <piral.Extension name={props.pageName} params={{ element: props.children }} />;
