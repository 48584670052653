import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const defaultDateTimeFormat = 'D MMM YYYY [at] h:mm a';
const defaultDateFormat = 'D MMM YYYY';

/**
 * Format a date in 'D MMM YYYY' format.
 *
 * @param {string} date - the date to format.
 * @returns {string} - the formatted date.
 */
export const formatDate = (date: string | Date): string => {
  return date ? dayjs(date).format(defaultDateFormat) : '';
};

/**
 * Format a date with a format of 'format' and returning using 'D MMM YYYY' format.
 *
 * @param {string} date - a date that is in the format specified in the format parameter.
 * @param {string} format - the current format for the date.
 * @returns {string} the formatted date.
 */
export const formatDateWithSourceFormat = (date: string, format: string): string => {
  return dayjs(date, format).format(defaultDateFormat);
};

/**
 * Format a date using 'D MMM YY [at] h:mm a' format.
 * @param {string} dateTime - a date in a standard ISO format.
 * @returns {string} the formatted date.
 */
export const formatDateTime = (dateTime: string): string => {
  return dayjs(dateTime).format(defaultDateTimeFormat);
};

/**
 * Format a date with a format of 'format' and returning using 'D MMM YY [at] h:mm a' format.
 *
 * @param {string} date - a date that is in the format specified in the format parameter.
 * @param {string} format - the current format for the date.
 * @returns {string} the formatted date.
 */
export const formatDateTimeWithSourceFormat = (dateTime: string, format: string): string => {
  return dayjs(dateTime, format).format(defaultDateTimeFormat);
};

/**
 * Comparator to sort two dates. Dates can be a date, string or null.
 *
 * @param {Date | string | null} a The first date to compare.
 * @param {Date | string | null} b The second date to compare.
 * @returns {number} The comparison of the two dates
 */
export const sortDates = (a: Date | string | null, b: Date | string | null): number => {
  if (!a || !b) return 0;
  const a1 = new Date(a).getTime();
  const b1 = new Date(b).getTime();
  if (a1 < b1) return -1;
  else if (a1 > b1) return 1;
  else return 0;
};

/**
 * The date format used for API dates.
 */
export const apiDateFormat = 'YYYY-MM-DDTHH:mm:ssZ[Z]';

/**
 * Check if a date is in the valid format for an API date.
 *
 * @param {string} value - The date to check.
 * @returns {boolean} - true if the date is an API date otherwise false.
 */
export const isValidAPIDate = (value: string): boolean => {
  // We only have one format for Dates returned by our API: ISO 8601. We have to rely on it to ensure date parsing works.
  // (try to parse 'Note - 1' as a date with the standard javaScript function or with dayjs without specifying a format and it will pass!!!)
  return dayjs(value, apiDateFormat).isValid();
};

/**
 * Validates and formats a date value.
 *
 * @param value The date value to validate and format.
 * @returns The formatted date string, or a dash character if the date is invalid or empty.
 */
export const validateAndFormatDate = (value: string | Date): string => {
  if (!value || value.toString() === 'Invalid Date') return '-';
  const formattedDate = formatDate(value);
  return formattedDate !== '' ? formattedDate : '-';
};
